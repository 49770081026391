import { useState, useEffect } from "react";
import { callAPI } from "../../../utils/API";
import { Alert, TextField } from "@mui/material";
import "./PhotosPage.css";

interface PhotoAlbum {
  id: string;
  name: string;
  preview_img: string; // base64 encoded
  album_url: string;
  date: string;
  author: number;
  num_items: number;
}

function PhotoAlbumCard({ photoAlbum }: { photoAlbum: PhotoAlbum }) {
  return (
    <a
      className="photo-album"
      target="_blank"
      rel="noreferrer"
      href={photoAlbum.album_url}
      style={{
        transition: "none 0s ease 0s",
      }}
    >
      <div className="photo-album-image" style={{
        backgroundImage: `url(${photoAlbum.preview_img})`,
      }}></div>
      <div className="photo-album-details">
        <div className="photo-album-controls">
          <span onClick={() => null}>Edit</span> &nbsp;·&nbsp; <span onClick={() => null}>Delete</span>
        </div>
        <div className="photo-album-name" dir="auto">{photoAlbum.name}</div>
        <div className="photo-album-info">{photoAlbum.num_items} items &nbsp;·&nbsp; {new Date(photoAlbum.date).toLocaleDateString()} &nbsp;·&nbsp; {photoAlbum.author}</div>
      </div>
    </a>
  );
}

function PhotoAlbumsPage() {
  const [photoAlbums, setPhotoAlbums] = useState<PhotoAlbum[]>([]);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    callAPI("tables/photoAlbums")
      .then((result) => {
        setPhotoAlbums(result);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const filteredPhotoAlbums = photoAlbums.filter((photoAlbum) =>
    photoAlbum.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (error) {
    return (
      <div className="container mt-2">
        <h1>Photos</h1>
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Photos</h1>
      <TextField
        label="Search Photos"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="my-3 row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-xl-6 row-cols-lg-6">
        {filteredPhotoAlbums.map((photoAlbum) => (
          <PhotoAlbumCard photoAlbum={photoAlbum} key={photoAlbum.id} />
        ))}
      </div>
    </div>
  );
}

export default PhotoAlbumsPage;