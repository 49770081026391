import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { callAPI } from "../../utils/API";
import "./PublicHomePage.css";

interface HomePageSection {
  id: number;
  title: string;
  heroText: string | ((data: any) => string);
  description: string | ((data: any) => string);
  imageUrl: string;
  action: {
    text: string;
    link: string;
  };
}

const defaultSection: HomePageSection = {
  id: -1,
  title: "Upcoming Events",
  heroText: (data: any) => data?.name || "No events found",
  description: (data: any) => data?.description || "Check back later",
  imageUrl: "https://troop600.com/assets/img/mountain.jpg",
  action: {
    text: "Troop Calendar",
    link: "/calendar",
  }
};

const Section: React.FC<{ section: HomePageSection, upcomingEvent: any }> = ({ section, upcomingEvent }) => (
  <div className="section">
    <div className="background bg-l" style={{ backgroundImage: `url(${section.imageUrl})` }}></div>
    <div className="section-inner resize" style={{ height: "937px" }}>
      <div className="inner-left-bottom">
        <h4 style={{ textTransform: "uppercase", opacity: 1 }} className="animate">{section.title}</h4>
        <h2 className="custom-hero shadowed" style={{ opacity: 1 }}>
          {typeof section.heroText === "function" ? section.heroText(upcomingEvent) : section.heroText}
        </h2>
        <p className="animate shadowed" style={{ opacity: 1 }}>
          {typeof section.description === "function" ? section.description(upcomingEvent) : section.description}
        </p>
        <Link className="custom-btn animate" to={section.action.link} style={{ opacity: 1 }}>
          <span className="text">{section.action.text}</span>
        </Link>
      </div>
    </div>
  </div>
);

const PublicHomePage: React.FC = () => {
  const [upcomingEvent, setUpcomingEvent] = useState(null);
  const [sections, setSections] = useState<HomePageSection[]>([defaultSection]);

  useEffect(() => {
    async function fetchUpcomingEvent() {
      const response = await callAPI("/public/upcomingEvents");
      setUpcomingEvent(response);
    }

    async function fetchSections() {
      const response = await callAPI("/public/homePage");
      setSections([defaultSection, ...response.sort(
        (a: HomePageSection, b: HomePageSection) => a.id - b.id
      )]);
    }

    fetchUpcomingEvent();
    fetchSections();
  }, []);

  return (
    <div className="public-home">
      {sections.map((section, index) => (
        <Section key={index} section={section} upcomingEvent={upcomingEvent} />
      ))}
    </div>
  );
};

export default PublicHomePage;