import { Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { callAPI } from "../../utils/API";
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from "react-router-dom";
import BSACircularProgress from "../home/widgets/BSACircularProgress";

export interface CalendarEvent {
  id: number;
  summary: string;
  description: string;
  location: string;
  start: number;
  end: number;
  signup_enabled: 0 | 1;
}

function CalendarEventPage() {
  const { id } = useParams();
  const [event, setEvent] = useState<CalendarEvent | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    callAPI(`tables/calendar`)
      .then((results) => {
        const result = results.find((event: CalendarEvent) => event.id === Number(id));
        setEvent(result);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  }, [id]);

  const navigate = useNavigate();

  if (error) {
    return (
      <div className="container mt-2">
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <BSACircularProgress />
      </div>
    );
  }

  const starts = new Date(event.start * 1000);
  const ends = new Date(event.end * 1000);
  const sanitizedHTML = DOMPurify.sanitize(event.description, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="container mt-4">
      <Button variant="outlined" className="mb-2 me-2" onClick={() => navigate("/calendar")}>Back to Calendar</Button>
      <Button variant="outlined" className="mb-2 me-2" onClick={() => navigate(`/calendar/events/${event.id}/edit`)}>Edit</Button>
      <div className="card shadow-sm">
        <div className="card-body">
          <h1 className="card-title border-bottom mb-3 pb-1">{event.summary}</h1>
          <div className="card-text" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
          <p className="card-text"><strong>Location:</strong> {event.location}</p>
          <p className="card-text"><strong>Time:</strong> {starts.toLocaleString()} - {ends.toLocaleString()}</p>
          {event.signup_enabled === 1 && (
            <div className="d-grid">
              <Button variant="outlined">Sign Up</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CalendarEventPage;