import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useState } from "react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import { callAPI } from "../../utils/API";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";

const formatDate = (date: Date) => {
  const datePart = date.toLocaleDateString('en-CA'); // 'en-CA' gives YYYY-MM-DD format
  const timePart = date.toLocaleTimeString('en-GB', { hour12: false }); // 'en-GB' gives HH:MM:SS format
  return `${datePart} ${timePart}`;
};

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    callAPI("tables/calendar")
      .then((result) => {
        setEvents(result.map((event: any) => {
          const startDate = new Date(event.start * 1000);
          const endDate = new Date(event.end * 1000);
          return {
            id: event.id,
            title: event.summary,
            start: formatDate(startDate),
            end: formatDate(endDate),
          };
        }));
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  return (
    <div className="container mt-2">
      {error && <Alert severity="error">{error}</Alert>}
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        weekends={false}
        events={events}
        dateClick={(info) => {
          navigate(`/calendar/events/add?date=${info.dateStr}`);
        }}
        eventClick={(info) => {
          navigate(`/calendar/events/${info.event.id}`);
        }}
      // eventContent={renderEventContent}
      />
    </div>
  );
};

export default CalendarPage;