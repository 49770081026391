import { useEffect, useState } from "react";
import { callAPI } from "../../../utils/API";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";
import { Alert } from "@mui/material";

interface DynamicPage {
  title: string;
  contents: string;
  slug: string;
}

function DynamicPageCard({ page }: { page: DynamicPage }) {
  const sanitizedContent = DOMPurify.sanitize(page.contents, {
    FORBID_TAGS: ["iframe", "script"],
    FORBID_ATTR: ["href"],
  }).replace(/<\/?[^>]+(>|$)/g, "").replaceAll('color:rgb(255,255,255);', 'color:default;');
  const contentPreview = sanitizedContent.substring(0, 100);
  return (
    <div className="col">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{page.title}</h5>
          <p className="card-text">{contentPreview}...</p>
          <Link to={`/admin/pages/${page.slug}`} className="btn btn-primary">Edit</Link>
        </div>
      </div>
    </div>
  );
}

export default function ManageDynamicPages() {
  const [pages, setPages] = useState<DynamicPage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    callAPI("tables/pages")
      .then((result) => {
        setPages(result);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.toString());
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-2">
      <h1>Dynamic Pages</h1>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        <div className="col">
          <div className="card">
            <div className="card-header">Create New Page</div>
            <div className="card-body">
              <Link to="/admin/pages/create" className="btn btn-outline-primary">Create</Link>
            </div>
          </div>
        </div>
        {error && (
          <Alert severity="error" className="col">
            {error}
          </Alert>
        )}
        {pages.map((page) => (
          <DynamicPageCard key={page.slug} page={page} />
        ))}
        {loading && (
          <div className="col">
            <div className="card py-5">
              <BSACircularProgress />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
