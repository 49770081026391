import { getUserInfo } from "../../utils/API";

export default function AccountPage() {
  const userInfo = getUserInfo();
  return (
    <div className="container mt-2">
      <h1>Account</h1>
      <div className="d-flex gap-2 flex-wrap">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Account</h5>
            <p className="card-text">
              <strong>Name:</strong> {userInfo.name}
            </p>
            <p className="card-text">
              <strong>Email:</strong> {userInfo.email}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
