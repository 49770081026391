import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom"; // import useParams
import { callAPI } from "../../../utils/API";
import { Alert, Button } from "@mui/material";
import { TROOP_DOMAIN } from "../../../utils/config";
import DOMPurify from "dompurify";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";

interface MeritBadge {
  id: string;
  name: string;
  img: string;
  eagle_required: 0 | 1;
  requirements: string;
  workbook_url: string;
}

function MeritBadgeDetailsPage() {
  const [meritBadge, setMeritBadge] = useState<MeritBadge | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>(); // initialize useParams

  useEffect(() => {
    callAPI(`tables/meritBadges`)
      .then((result) => {
        setMeritBadge(result.find((mb: MeritBadge) => mb.name.replace(/ /g, "-").toLowerCase() === id) || null);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  if (error) {
    return (
      <div className="container mt-2">
        <Link to="/resources/merit-badges" className="text-white text-decoration-none">
          <h1>Merit Badges</h1>
        </Link>
        <div className="card mt-2">
          <Alert severity="error">{error}</Alert>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container mt-2">
        <Link to="/resources/merit-badges" className="text-white text-decoration-none">
          <h1>Merit Badges</h1>
        </Link>
        <div className="card progress-container py-5">
          <BSACircularProgress />
        </div>
      </div>
    );
  }

  if (!meritBadge) {
    return (
      <div className="container mt-2">
        <Link to="/resources/merit-badges" className="text-decoration-none">
          <h1>Merit Badges</h1>
        </Link>
        <div className="card mt-2">
          <h1>Merit Badge Details</h1>
          <p>Merit badge not found.</p>
        </div>
      </div>
    );
  }

  const sanitizedHTML = DOMPurify.sanitize(meritBadge.requirements, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="container mt-2">
      <Link to="/resources/merit-badges" className="text-white text-decoration-none">
        <h1>Merit Badges</h1>
      </Link>
      <div className="card mt-2">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img src={`https://${TROOP_DOMAIN}/${meritBadge.img}`} alt={meritBadge.name} className="badge-img me-2" />
            <h1 className="badge-name mt-2">{meritBadge.name}</h1>
          </div>
          <Button variant="contained" disabled={!meritBadge.workbook_url} color="primary" href={meritBadge.workbook_url} target="_blank" rel="noreferrer">
            Workbook
          </Button>
        </div>
        <div className="card-body">
          <p>Eagle Required: {meritBadge.eagle_required ? "Yes" : "No"}</p>
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        </div>
      </div>
    </div>
  );
}

export default MeritBadgeDetailsPage;