import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Announcement, AnnouncementCard } from "../../announcements/AnnouncementsPage";
import { callAPI } from "../../../utils/API";
import BSACircularProgress from "./BSACircularProgress";

function AnnouncementsWidget() {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const sortedAnnouncements = announcements.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  useEffect(() => {
    callAPI("tables/announcements")
      .then((result) => {
        setAnnouncements(result);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h1>Announcements</h1>
          <div className="progress-container">
            <BSACircularProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h1>Announcements</h1>
        <div className="my-3 d-flex gap-2 flex-column">
          {error ? <Alert severity="error">{error}</Alert> : sortedAnnouncements.map((announcement) => (
            <AnnouncementCard announcement={announcement} onDelete={() => { }} key={announcement.id} />
          ))}
          {sortedAnnouncements.length === 0 && <p>There aren't any announcements yet. Check back later.</p>}
        </div>
      </div>
    </div>
  );
}

export default AnnouncementsWidget;