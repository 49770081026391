import { useState, useEffect } from "react";
import { callAPI, getUserInfo } from "../../utils/API";
import { Alert, Button, Modal, Paper, TextField } from "@mui/material";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BSACircularProgress from "../home/widgets/BSACircularProgress";

export interface Announcement {
  id: string;
  title: string;
  createdBy: number;
  createdAt: string;
  content: string;
}

export function AnnouncementCard({ announcement, onDelete }: { announcement: Announcement, onDelete: (id: string) => void }) {
  const sanitizedHTML = DOMPurify.sanitize(announcement.content, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  const handleDelete = () => {
    callAPI(`tables/announcements/delete/${announcement.id}`, "DELETE")
      .then(() => {
        onDelete(announcement.id);
      })
      .catch((err) => {
        alert("Failed to delete announcement: " + err.message);
      });
  };

  return (
    <div className="card w-100">
      <div className="card-body">
        {announcement.createdBy === getUserInfo().sub && (
          <span className="card-delete" title="Delete" onClick={handleDelete}>&times;</span>
        )}
        <h5 className="card-title">{announcement.title}</h5>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </div >
  );
}

function AnnouncementsPage() {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newContent, setNewContent] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    callAPI("tables/announcements")
      .then((result) => {
        setAnnouncements(result);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const userInfo = getUserInfo();

  const handleAddAnnouncement = () => {
    const newAnnouncement = {
      id: Date.now().toString(),
      title: newTitle,
      createdBy: userInfo.sub,
      createdAt: new Date().toISOString(),
      content: newContent,
    };

    callAPI("tables/announcements/add", "POST", newAnnouncement)
      .then((result) => {
        setAnnouncements([...announcements, result]);
        setAddModalOpen(false);
        setNewTitle("");
        setNewContent("");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const sortedAnnouncements = announcements.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  if (loading) {
    return (
      <div className="container mt-2">
        <h1>Announcements</h1>
        <div className="progress-container card mt-2 py-5">
          <BSACircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Announcements</h1>
      <Button onClick={() => setAddModalOpen(true)} variant="outlined">Add New</Button>
      <div className="my-3 d-flex gap-2 flex-column">
        {error ? <Alert severity="error">{error}</Alert> : sortedAnnouncements.map((announcement) => (
          <AnnouncementCard announcement={announcement} onDelete={() => {
            setAnnouncements(announcements.filter((a) => a.id !== announcement.id));
          }} key={announcement.id} />
        ))}
      </div>
      <Modal open={addModalOpen} onClose={() => setAddModalOpen(false)}>
        <Paper
          className="modal-content"
          style={{
            padding: '20px',
            maxWidth: '800px',
            margin: '10% auto',
            position: 'relative',
          }}
        >
          <div className="d-flex flex-column gap-2">
            <h2>Add New Announcement</h2>
            <TextField
              label="Title"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              fullWidth
            />
            <CKEditor
              editor={ClassicEditor}
              data=""
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setNewContent(data);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
            <Button onClick={handleAddAnnouncement} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}

export default AnnouncementsPage;