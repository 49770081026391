import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { callAPI } from "../../utils/API";
import NotFoundPage from "../ui/NotFoundPage";
import BSACircularProgress from "../home/widgets/BSACircularProgress";
import DOMPurify from "dompurify";

interface Page {
  title: string;
  contents: string;
}

export default function DynamicPage() {
  const { slug } = useParams<{ slug: string }>();
  const [page, setPage] = useState<Page | null>(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    async function getPage() {
      try {
        const response = await callAPI(`/public/page/${slug}`);
        setPage(response);
        if (!response) {
          setNotFound(true);
        }
      } catch (error) {
        setNotFound(true);
      }
    }

    getPage();
  }, [slug]);

  if (notFound) {
    return <NotFoundPage />;
  }

  if (!page) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <BSACircularProgress />
      </div>
    );
  }

  const sanitizedHTML = DOMPurify.sanitize(page.contents, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="container mt-2">
      <h1>{page.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  );
}