import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { callAPI } from "../../../utils/API";
import DOMPurify from "dompurify";
import { CalendarEvent } from "../../calendar/CalendarEvent";
import BSACircularProgress from "./BSACircularProgress";

export function UpcomingEventCard({ event }: { event: CalendarEvent }) {
  const sanitizedHTML = DOMPurify.sanitize(event.description, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">{event.summary}</h5>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </div >
  );
}

function UpcomingEventsWidget() {
  const [upcomingEvents, setUpcomingEvents] = useState<CalendarEvent[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const filteredEvents = upcomingEvents.sort((a, b) => {
    return new Date(b.start).getTime() - new Date(a.start).getTime();
  }).filter((event) => {
    return new Date(event.start).getTime() >= new Date().getTime();
  });

  useEffect(() => {
    callAPI("tables/calendar")
      .then((result) => {
        setUpcomingEvents(result);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h1>Upcoming Events</h1>
          <div className="progress-container">
            <BSACircularProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h1>Upcoming Events</h1>
        <div className="my-3 d-flex gap-2 flex-column">
          {error ? <Alert severity="error">{error}</Alert> : filteredEvents.map((announcement) => (
            <UpcomingEventCard event={announcement} key={announcement.id} />
          ))}
          {filteredEvents.length === 0 && <p>No upcoming events</p>}
        </div>
      </div>
    </div>
  );
}

export default UpcomingEventsWidget;