import { Button, MenuItem, TextField } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { callAPI } from "../../utils/API";

const AddCalendarEventPage: React.FC = () => {
  const [editorData, setEditorData] = useState("");
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [location, setLocation] = useState("");
  const [url, setUrl] = useState("");
  const [visibility, setVisibility] = useState("public");

  const handleSave = async () => {
    callAPI("/tables/calendar/add", "POST", {
      eventName,
      startDate,
      endDate,
      location,
      url,
      visibility,
      description: editorData,
    });
  };

  return (
    <div className="container mt-2">
      <h1>Add Calendar Event</h1>
      <div className="d-flex flex-column gap-2">
        <TextField
          label="Event Name"
          fullWidth
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
        />
        <DateTimePicker
          label="Start Date"
          value={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <DateTimePicker
          label="End Date"
          value={endDate}
          onChange={(date) => setEndDate(date)}
        />
        <TextField
          label="Location"
          fullWidth
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <TextField
          label="URL"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <TextField
          label="Visibility"
          select
          fullWidth
          value={visibility}
          onChange={(e) => setVisibility(e.target.value)}
        >
          <MenuItem value="public">Feature on homepage when not logged in</MenuItem>
          <MenuItem value="private">Do not feature on homepage when not logged in</MenuItem>
        </TextField>
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
        <div className="d-flex justify-content-end gap-2">
          <Button variant="contained" component={Link} to="/calendar">Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
        </div>
      </div>
    </div>
  );
}

export default AddCalendarEventPage;