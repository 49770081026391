import { useState, useEffect } from "react";
import { callAPI } from "../../../utils/API";
import { Alert, TextField } from "@mui/material";

interface RosterUser {
  id: string;
  name: string;
  email: string;
  rank: string;
  position: string;
  patrol: string;
}

function RosterUserCard({ user }: { user: RosterUser }) {
  return (
    <div className="card" style={{ width: "100%", maxWidth: "250px" }}>
      <div className="card-body">
        <h5 className="card-title">{user.name}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{user.rank}</h6>
        <p className="card-text">{user.position}</p>
        <p className="card-text">{user.patrol}</p>
      </div>
    </div >
  );
}

function RosterPage() {
  const [users, setUsers] = useState<RosterUser[]>([]);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    callAPI("tables/troopRoster")
      .then((result) => {
        setUsers(result);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (error) {
    return (
      <div className="container mt-2">
        <h1>Troop Roster</h1>
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Troop Roster</h1>
      <TextField
        label="Search Troop Roster"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="my-3 d-flex gap-2 flex-wrap">
        {filteredUsers.map((user) => (
          <RosterUserCard user={user} key={user.id} />
        ))}
      </div>
    </div>
  );
}

export default RosterPage;