import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { callAPI } from "../../../utils/API";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface DynamicPage {
  title: string;
  contents: string;
  slug: string;
}

export default function EditDynamicPage() {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [page, setPage] = useState<DynamicPage | null>(null);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");

  useEffect(() => {
    const fetchPage = async () => {
      const results = await callAPI(`tables/pages`);
      const result = results.find((page: DynamicPage) => page.slug === slug);
      setPage(result);
      setTitle(result.title);
      setContents(result.contents);
    };

    fetchPage();
  }, [slug]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const sanitizedContents = DOMPurify.sanitize(contents, {
      FORBID_TAGS: ["iframe", "script"],
      FORBID_ATTR: ["href"],
    }).replaceAll('color:rgb(255,255,255);', 'color:default;');

    await callAPI(`tables/pages/${slug}`, "POST", { slug, title, contents: sanitizedContents });
    navigate("/admin/pages");
  };

  if (!page) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-2">
      <h1>Edit Page</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="contents" className="form-label">Contents</label>
          <CKEditor
            editor={ClassicEditor}
            data={contents}
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContents(data);
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
        </div>
        <button type="submit" className="btn btn-outline-primary">Save</button>
        <Link to="/admin/pages" className="btn btn-outline-secondary ms-2">Cancel</Link>
      </form>
    </div>
  );
}